import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import { mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import {
  today,
  last7,
  last30,
  last90,
  firstDateOfMonth,
  lastDateOfMonth,
  kompackDate,
} from '@/store/helpers'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import dataBarangKeluar from './table/dataBarangKeluar.vue'
import menungguRespon from './table/menungguRespon.vue'
import modal from '../modal/modalTable.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  data() {
    return {
      // Date range picker
      picker: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      ranges: {
        'Semua ': [kompackDate, today],
        '7 Hari Terakhir': [last7, today],
        '1 Bulan Terakhir': [last30, today],
        '3 Bulan Terakhir': [last90, today],
        'Custom Tanggal': [today, today],
      },
      formatDate(d) {
        return moment(d).format('D MMM YYYY')
      },
      listStatus: [
        {
          text: 'Semua',
          value: '',
        },
        {
          text: 'Menunggu respon partner',
          value: 'Diajukan',
        },
        {
          text: 'Disetujui',
          value: 'Diproses',
        },
        {
          text: 'Selesai',
          value: 'Selesai',
        },
        {
          text: 'Ditolak',
          value: 'Ditolak',
        },
      ],
      loading: true,
      tabIndex: 0,
      lastData: true,
      tabs: ['data-barang-dikeluarkan', 'menunggu-response'],
    }
  },
  components: {
    dataBarangKeluar,
    menungguRespon,
    DateRangePicker,
    modal,
    vSelect,
  },
  beforeMount() {
    this.table.offsetOutbound = 0
    this.table.offsetWaiting = 0
    this.$store.dispatch('outboundNonPacking/init')
      .then(() => { })
      .catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text:
              'Unable to load the table data. Please try again later or contact support.',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    ...mapFields('outboundNonPacking', [
      'dateRange',
      'partnerSelected',
      'statusSelected',
      'table',
      'nextFetchListOutbound',
      'nextFetchListWaiting',
    ]),
    ...mapState('outboundNonPacking', [
      'listOutboundNonPickup',
      'listWaitingResponse',
      'detailOutbound',
    ]),
    ...mapGetters('outboundNonPacking', ['listPartners']),
  },
  methods: {
    async onSubmitFilter() {
      this.loading = true
      this.table.offsetOutbound = 0
      this.table.offsetWaiting = 0
      this.$refs.btnFilter.click()

      if (this.tabIndex === 0) {
        await this.$store.dispatch('outboundNonPacking/getListOutboundNonPickup')
          .then(() => { })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text:
                  'Unable to load the table data. Please try again later or contact support.',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        await this.$store.dispatch('outboundNonPacking/getListWaitingResponse')
          .then(() => { })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text:
                  'Unable to load the table data. Please try again later or contact support.',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async toDetail(id) {
      await this.$store.commit('outboundNonPacking/UPDATE_ID_DETAIL', id)
      this.$store.dispatch('outboundNonPacking/getDetailOutbound')
        .then(() => {
          this.$bvModal.show('modal-table-broken-item-submission')
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text:
                'Unable to load the table data. Please try again later or contact support.',
              variant: 'danger',
            },
          })
        })
    },
    onReset() {
      this.statusSelected = ''
      this.partnerSelected = ''
      this.table.offsetOutbound = 0
      this.table.offsetWaiting = 0
      this.dateRange = {
        startDate: kompackDate,
        endDate: today,
      }

      this.afterReset()
      this.$refs.btnFilter.click()
    },
    async afterReset() {
      if (this.tabIndex === 0) {
        this.loading = true
        await this.$store.dispatch('outboundNonPacking/getListOutboundNonPickup')
          .then(() => { })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text:
                  'Unable to load the table data. Please try again later or contact support.',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      }

      if (this.tabIndex === 1) {
        this.loading = true
        await this.$store.dispatch('outboundNonPacking/getListWaitingResponse')
          .then(() => { })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text:
                  'Unable to load the table data. Please try again later or contact support.',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async handleScroll() {
      if (this.tabIndex === 0 && this.nextFetchListOutbound) {
        this.loading = true
        await this.$store.dispatch('outboundNonPacking/nextListOutboundNonPickup')
          .then(() => { })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text:
                  'Unable to load the table data. Please try again later or contact support.',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      }

      if (this.tabIndex === 1 && this.nextFetchListWaiting) {
        this.loading = true
        await this.$store.dispatch('outboundNonPacking/nextListWaitingResponse')
          .then(() => { })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text:
                  'Unable to load the table data. Please try again later or contact support.',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    resetFilter() {
      this.statusSelected = ''
      this.partnerSelected = ''
      this.dateRange = {
        startDate: kompackDate,
        endDate: today,
      }
    },
  },
  mounted() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) > document.getElementById('docs').offsetHeight && !this.loading) {
        this.handleScroll()
      }
    }
  },
  watch: {
    tabIndex(newTab, oldtab) {
      this.resetFilter()

      if (newTab === 0) {
        this.loading = true
        const tab = this.tabs[newTab]
        this.$router.replace({ query: { tab } }).catch(err => {})

        this.$store.dispatch('outboundNonPacking/getListOutboundNonPickup')
          .then(() => { })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text:
                  'Unable to load the table data. Please try again later or contact support.',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = true
        const tab = this.tabs[newTab]
        this.$router.replace({ query: { tab } }).catch(err => {})

        this.$store.dispatch('outboundNonPacking/getListWaitingResponse')
          .then(() => { })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text:
                  'Unable to load the table data. Please try again later or contact support.',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
