<template>
  <section>
    <b-modal
      id="modal-table-broken-item-submission"
      body-class="p-1"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <b-overlay
        variant="light"
        spinner-variant="primary"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <div class="container d-flex flex-column gap-12 pad-24 pad-y-32">
          <div class="d-flex align-items-center gap-12">
            <h4 class="my-0 text-dark text-10">
              Pengajuan barang rusak
            </h4>
            <div
              v-if="detailOutbound.status === 'Selesai'"
              class="status status-completed"
            >
              Selesai
            </div>
            <div
              v-if="detailOutbound.status === 'Ditolak'"
              class="status status-reject"
            >
              Ditolak
            </div>
            <div
              v-if="detailOutbound.status === 'Disetujui'"
              class="status status-process"
            >
              Disetujui
            </div>
            <div
              v-if="detailOutbound.status === 'Diajukan'"
              class="status status-submit"
            >
              Menunggu respon partner
            </div>
          </div>
          <div class="d-flex align-items-center">
            <h4 class="my-0 text-dark text-8">
              {{ detailOutbound.partner_name }}
            </h4>
          </div>
          <div>
            <b-table
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              class="text-center"
              :fields="fields"
              :items="detailOutbound.products"
              responsive
              :tbody-tr-class="'bg-white'"
            >
              <template #cell(product_name)="data">
                <div class="h-text-dark">
                  {{ data.item.nama_product }}
                </div>
              </template>
              <template #cell(variant)="data">
                <div class="h-text-dark">
                  {{ data.item.variant ? data.item.variant : '-' }}
                </div>
              </template>
              <template #cell(stock)="data">
                <div class="h-text-dark">
                  {{ data.item.stok }}
                </div>
              </template>
              <template #cell(total_submission)="data">
                <div class="h-text-dark">
                  {{ data.item.total }}
                </div>
              </template>
            </b-table>
          </div>
          <div
            v-if="detailOutbound.memo"
            class="text-[#222222]"
          >
            Memo
          </div>
          <div
            v-if="detailOutbound.memo"
            class="textarea-memo"
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="handleMemo(detailOutbound.memo)" />
          </div>
          <div class="d-flex w-100 justify-content-end">
            <b-button
              variant="outline-primary"
              @click="$bvModal.hide('modal-table-broken-item-submission')"
            >
              Kembali
            </b-button>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
export default {
  props: {
    detailOutbound: Object,
  },
  data() {
    return {
      fields: [
        {
          key: 'product_name', label: 'Nama Produk', tdClass: 'text-left', thClass: 'text-capitalize text-left',
        },
        {
          key: 'variant', label: 'Variasi', tdClass: 'text-left', thClass: 'text-capitalize text-left',
        },
        {
          key: 'stock', label: 'Stok', tdClass: 'text-left', thClass: 'text-capitalize text-left',
        },
        {
          key: 'total_submission', tdClass: 'text-left', label: 'Jumlah Pengajuan', thClass: 'text-capitalize text-left',
        },
      ],
    }
  },
  methods: {
    handleMemo(memo) {
      const urlRegex = /(https?:\/\/[^\s]+)/g

      const memoWithLinks = memo ? memo.replace(urlRegex, '<a href="$1" target="_blank">$1</a>') : ''

      return memoWithLinks
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import '@core/scss/vue/libs/vue-select.scss';

$primary: #f95031;
$orange: #ff6a3a;
$grayscale: #828282;
$black--text: #222222;

.container {
  font-family: 'Poppins', sans-serif;
}

.button-secondary {
  background-color: #fff;
  border: 1px solid $primary;
  color: $primary;
  border-radius: 8px;
  font-weight: 500;
}

.button-primary {
  background-color: $primary;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 8px;
  font-weight: 500;
}

.text {
  @for $i from 1 through 16 {
    &-#{$i} {
      font-size: #{$i * 2}px;
    }
  }
}

.text-dark {
  color: #222222 !important;
}

.gap {
  @for $i from 1 through 32 {
    &-#{$i} {
      gap: #{$i}px;
    }
  }
}

.status {
  border-radius: 20px;
  padding: 1px 10px;

  &-submit {
    color: #fba63c;
    background-color: #fcebbe;
  }

  &-completed {
    color: #4285f4;
    background-color: #dff3ff;
  }

  &-process {
    color: #34a770;
    background-color: #befcde;
  }

  &-reject{
      color: #E31A1A;
      background-color: #FFEDED;
    }
}
.textarea-memo {
  border: 1px solid #e2e2e2;
  padding: 12px 16px;
  font-size: 14px;
  min-height: 100px;
  border-radius: 8px;
}
</style>
