<template>
  <b-table
    id="table"
    show-empty
    empty-text="Tidak ada data yang ditampilkan."
    class="text-center"
    :fields="fields"
    :items="listOutbound"
    responsive
    :tbody-tr-class="'bg-white'"
  >
    <!-- custom table head -->
    <template #head(date)="data">
      <span class="text-14 text-dark">{{ data.label }}</span>
    </template>
    <template #head(reason)="data">
      <span class="text-14 text-dark">{{ data.label }}</span>
    </template>
    <template #head(partner)="data">
      <span class="text-14 text-dark">{{ data.label }}</span>
    </template>
    <template #head(quantity)="data">
      <span class="text-14 text-dark">{{ data.label }}</span>
    </template>
    <template #head(status)="data">
      <span class="text-14 text-dark">{{ data.label }}</span>
    </template>
    <template #head(detail)="data">
      <span class="text-14 text-dark">{{ data.label }}</span>
    </template>

    <template #cell(date)="data">
      <div class="text-dark text-start">
        {{ formatDate(data.item.outbound_date) }}
      </div>
    </template>
    <template #cell(reason)="data">
      <div class="text-dark">
        {{ data.item.reason }}
      </div>
    </template>
    <template #cell(partner)="data">
      <div class="text-dark">
        {{ data.item.partner_name }}
      </div>
    </template>
    <template #cell(quantity)="data">
      <div class="text-dark">
        {{ data.item.total_product }}
      </div>
    </template>
    <template #cell(status)="data">
      <span
        v-if="data.item.status === 'Selesai'"
        class="status status-completed"
      >
        Selesai
      </span>
      <span v-if="data.item.status === 'Diajukan'
      && data.item.reason !== 'Barang Rusak'"
      class="status status-submit">
        Diajukan
      </span>
      <span v-if="data.item.status === 'Diajukan'
        && data.item.reason === 'Barang Rusak'"
        class="status status-submit">
          Menunggu Respon Partner
      </span>
      <span v-if="data.item.status === 'Ditolak'" class="status status-reject">
        Ditolak
      </span>
      <span v-if="data.item.status === 'Diproses'" class="status status-process">
        Disetujui
      </span>
    </template>
    <template #cell(detail)="data">
      <div
        class="underline text-capitalize"
        style="color: #08a0f7"
        type="button"
        @click="handleClick(data.item.id)"
        v-if="data.item.reason === 'Barang Rusak'"
      >
        Lihat Detail
      </div>
      <router-link :to="`/outbound/pengeluaran-barang/detail/${data.item.id}`" v-else>
        <div
          class="underline text-capitalize"
          style="color: #08a0f7"
          type="button"
        >
          Lihat Detail
        </div>
      </router-link>
    </template>
  </b-table>
</template>

<script>
import moment from 'moment'
import { dataOutbound } from '../../tableconfig'

export default {
  props: {
    listOutbound: Array,
  },
  data() {
    return {
      fields: dataOutbound,
    }
  },
  methods: {
    formatDate(d) {
      return moment(d).format('D MMMM YYYY')
    },
    handleClick(id) {
      this.$emit('toDetail', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  width: fit-content;
  border-radius: 20px;
  padding: 1px 15px;
  font-size: 12px;

  &-submit {
    color: #fba63c;
    background-color: #FFF2E2;
  }
  &-completed {
    color: #4285f4;
    background-color: #dff3ff;
  }
  &-process {
    color: #34a770;
    background-color: #DCF3EB;
  }
  &-reject{
    color: #E31A1A;
    background-color: #FFEDED;
  }
}

.text-14 {
  font-size: 14px !important;
}

.text-dark {
  color: #222222 !important;
}
</style>
