<template>
  <b-table
    id="table"
    show-empty
    empty-text="Tidak ada data yang ditampilkan."
    class="text-center"
    :fields="fields"
    :items="listOutbound"
    responsive
    :tbody-tr-class="'bg-white'"
  >
    <!-- custom table header -->
    <template #head(date)="data">
      <span class="text-14 text-dark">{{ data.label }}</span>
    </template>
    <template #head(reason)="data">
      <span class="text-14 text-dark">{{ data.label }}</span>
    </template>
    <template #head(warehouse)="data">
      <span class="text-14 text-dark">{{ data.label }}</span>
    </template>
    <template #head(quantity)="data">
      <span class="text-14 text-dark">{{ data.label }}</span>
    </template>
    <template #head(detail)="data">
      <span class="text-14 text-dark">{{ data.label }}</span>
    </template>

    <template #cell(date)="data">
      <div class="text-dark text-start">
        {{ formatDate(data.item.outbound_date) }}
      </div>
    </template>
    <template #cell(reason)="data">
      <div class="text-dark">
        {{ data.item.reason }}
      </div>
    </template>
    <template #cell(warehouse)="data">
      <div class="text-dark">
        {{ data.item.partner_name }}
      </div>
    </template>
    <template #cell(quantity)="data">
      <div class="text-dark">
        {{ data.item.total_product }}
      </div>
    </template>
    <template #cell(detail)="data">
      <router-link :to="`/outbound/pengeluaran-barang/detail/${data.item.id}`">
        <div
          class="underline text-capitalize"
          style="color: #08a0f7"
          type="button"
        >
          Lihat Detail
        </div>
      </router-link>
    </template>
  </b-table>
</template>

<script>
import moment from 'moment'
import { waitingResponse } from '../../tableconfig'

export default {
  props: {
    listOutbound: Array,
  },
  data() {
    return {
      fields: waitingResponse,
    }
  },
  methods: {
    formatDate(d) {
      return moment(d).format('D MMMM YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  width: 100%;
  border-radius: 20px;
  padding: 1px 10px;

  &-submit {
    color: #fba63c;
    background-color: #fcebbe;
  }
  &-process {
    color: #4285f4;
    background-color: #dff3ff;
  }
  &-completed {
    color: #34a770;
    background-color: #befcde;
  }
}

.text-14 {
  font-size: 14px !important;
}

.text-dark {
  color: #222222 !important;
}
</style>
